import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Analytics } from '@vercel/analytics/react';
import axios from 'axios';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';


import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);

  useEffect(() => {
    const fetchLocationData = () => {
      fetch('/api/location')
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          setCountry(decodeURIComponent(data.country));
          setCity(decodeURIComponent(data.city));
          
          // Remove numbers from city and country names
          const cleanCity = data.city.replace(/[0-9]/g, '');
          const cleanCountry = data.country.replace(/[0-9]/g, '');
  
          const address = `${encodeURIComponent(cleanCity)}, ${encodeURIComponent(cleanCountry)}`;
          const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyBTT1Epe-qKRGcLSPos2waBZ-2M5dkMfew`;
          console.log("URL Requested: ", url); // Log the URL
          return axios.get(url);
        })
        .then(response => {
          console.log("Google Geocoding API Response: ", response.data); // Log the full response
          const results = response.data.results;
          if (results.length > 0) {
            const addressComponents = results[0].address_components;
            const stateObj = addressComponents.find(component => component.types.includes('administrative_area_level_1'));
            if (stateObj) {
              setRegion(stateObj.long_name);
            }
          }
          setFetchAttempted(true);
        })
        .catch(error => {
          console.error('Fetch error:', error);
          setCountry('Unavailable');
          setRegion('');
          setCity('');
          setFetchAttempted(true);
        });
    };
  
    if (!region && !fetchAttempted) {
      fetchLocationData();
    }
  }, [region]); // Dependency on region

  const imageUrls = [
    'https://i.ibb.co/MpbG6GH/cropped-photo-2024-02-28-20-24-12.jpg',
    'https://i.ibb.co/N92znHX/cropped-photo-2024-02-28-20-24-10.jpg',
    'https://i.ibb.co/dbZJ0tN/cropped-photo-2024-02-28-20-24-08.jpg',
    'https://i.ibb.co/ygwz37z/cropped-photo-2024-02-28-20-24-07.jpg',
    'https://i.ibb.co/JFwhrKb/cropped-photo-2024-02-28-20-24-06.jpg',
    'https://i.ibb.co/ftswjGH/cropped-photo-2024-02-28-20-24-04.jpg',
    'https://i.ibb.co/rb8V1dG/cropped-photo-2024-02-28-20-24-03.jpg',
    'https://i.ibb.co/W5m3YVM/cropped-photo-2024-02-28-20-24-01.jpg',
    'https://i.ibb.co/pPCJsx6/cropped-photo-2024-02-28-20-24-00.jpg',
    'https://i.ibb.co/XCs21S1/cropped-photo-2024-02-28-20-23-52.jpg'
  ];

  return (
    <div className="container">
      <img 
        src="https://assets.cdn.filesafe.space/CJkDD96k2jOfuywnYhgP/media/65a51f792aaf1b036608af13.png" 
        alt="Header Image"
        className="header-image"
      />
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={'3'}
        coverflowEffect={{
        rotate: 15,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
       }}
      autoplay={{ // Corrected from autoPlay to autoplay
        delay: 2000,
        disableOnInteraction: false,
      }}
      modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
      className="swiper-container"
    >
      {imageUrls.map(url => (
        <SwiperSlide key={url} className="swiper-slide">
          <img src={url} alt="slide_image"/>
        </SwiperSlide>
      ))}
        <div className="slider-controler">
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>

      <div className='textContainer'>
        <img src='https://i.ibb.co/KVFM8cX/photo-2024-02-28-20-24-01-modified.png' />
        <section>
            <div className='flexRow'>Micky ❤️<div className='buttonStyling'>Online Now</div></div>
            <div className='flexRow'>
              <span>
              21yr old blonde babe living in {city}, {region}   {country && <img 
                  src={`https://flagcdn.com/w20/${country.toLowerCase()}.png`} 
                  alt={`${country} Flag`}
                  style={{ width: '20px', height: '15px', verticalAlign: 'middle' }}
                />} 
            
                
              </span>
            </div>


        </section>
      </div>

      <a href="https://onlyfans.com/mmmbabyyy" id="customButton">
        Send me a message
        <img src="https://www.edigitalagency.com.au/wp-content/uploads/OnlyFans-logo-symbol-icon-png-blue-background.png" alt="Logo" />
      </a>

      <p className="exclusiveContent">
        Exclusive content 💖 50% off ($5.50) for 10 people only!!<br/><br/>
        🍑 💦 Do you live near <strong>{city}</strong>?📍 DM me about making content 😉 🍑 🔥

      </p>
      <Analytics/>
    </div>
  );
}

export default App;
